import { Box, Chip, Divider, Tab, Tabs } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useNormalizedCurrentRefinements from '../hooks/useNormalizedCurrentRefinements';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';
import { productSubFamilyImageMap } from '../discovery/state';

export default function CompanyProductSubFamiliesFilter(): React.JSX.Element {
  const { t } = useTranslation('product-family');
  const currentRefinements = useNormalizedCurrentRefinements('family') as string[];
  const [filterValue, setFilterValue] = useState<string | null>(currentRefinements[0] || null);
  const { items, refine } = useRefinementList({ limit: 100, attribute: 'family' });
  const sortedProductSubFamiliesOptions = items.sort((itemA, itemB) => itemB.count - itemA.count || itemA.value.localeCompare(itemB.value));
  const productsCount = sortedProductSubFamiliesOptions.reduce((acc, item) => item.count + acc, 0);
  const thereIsOnlyOneProductSubFamily = useMemo(() => (
    sortedProductSubFamiliesOptions.length === 1
  ), [sortedProductSubFamiliesOptions]);

  useEffect(() => {
    if (thereIsOnlyOneProductSubFamily) {
      setFilterValue(sortedProductSubFamiliesOptions[0].value);
    }
  }, [thereIsOnlyOneProductSubFamily]);

  const handleTabsChange = (_: SyntheticEvent, value: string | null): void => {
    setFilterValue(value);

    if (filterValue) {
      refine(filterValue);
    }

    if (value) {
      refine(value);
    }
  };

  return (
    <Tabs
      variant="scrollable"
      value={filterValue}
      sx={{
        width: {
          xs: '-webkit-fill-available',
          md: 'inherit',
        },
        '& .MuiTabs-flexContainer': {
          gap: 1,
        },
        '& .MuiTab-root': {
          padding: 0,
        },
      }}
      onChange={handleTabsChange}
      TabIndicatorProps={{
        sx: {
          display: 'none',
        },
      }}
      TabScrollButtonProps={{
        slots: {
          StartScrollButtonIcon: ArrowBackIcon,
          EndScrollButtonIcon: ArrowForwardIcon,
        },
        sx: {
          '&.Mui-disabled': {
            opacity: 1,
            color: 'text.disabled',
          },
          display: {
            xs: 'none',
            md: 'inline-flex',
          },
          opacity: 1,
          alignSelf: 'center',
        },
      }}
    >
      {!thereIsOnlyOneProductSubFamily && (
        <Tab
          disableRipple
          label={(
            <Chip
              variant="filter"
              label={`${t('common:all_products')} (${productsCount})`}
              className={filterValue === null ? 'Kheops-selected' : ''}
            />
          )}
          value={null}
        />
      )}
      <Divider flexItem orientation="vertical" sx={{ my: 1.5 }} />
      {sortedProductSubFamiliesOptions.map((item) => (
        <Tab
          key={item.value}
          disableRipple
          value={item.value}
          label={(
            <Chip
              variant="filter"
              avatar={(
                <Box
                  component="img"
                  src={productSubFamilyImageMap[item.value as Product_Sub_Family_Name_Enum]}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
              )}
              label={`${t(`product-family:${PackagingFamilyTypeIntl[item.value as Product_Sub_Family_Name_Enum]}`)} (${item.count})`}
              className={filterValue === item.value ? 'Kheops-selected' : ''}
              disabled={thereIsOnlyOneProductSubFamily}
            />
          )}
        />
      ))}
    </Tabs>
  );
}
