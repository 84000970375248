import { Box, Card, MenuItem, MenuList, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BackButton from '../common/components/BackButton';
import DiscountIcon from '../assets/icons/discount.svg?react';
import { RoutePaths } from '../routes/AppRoutes';
import { SupplierCompanyNavMap } from '../common/state/navigation';

export default function NavigationPrices(): React.JSX.Element {
  const { t } = useTranslation(['common', 'price', 'contracts']);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          px: 2,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          width: '100vw',
          height: '72px',
        }}
      >
        <BackButton compact to={RoutePaths.ROOT} />
        <DiscountIcon height={28} width={28} />
        <Typography variant="headlineSmall">
          {t('common:my_prices')}
        </Typography>
      </Box>
      <Card sx={{ m: 2, px: 2, py: 1 }}>
        <MenuList>
          {SupplierCompanyNavMap[3].subTabs.map((subTab) => (
            <MenuItem sx={{ py: 1 }} onClick={() => navigate(subTab.to)}>
              <Typography variant="bodyLarge">{t(subTab.label)}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Card>
    </>
  );
}
