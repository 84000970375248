import { Box, Chip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Base_Unit, Base_Unit_Type_Enum, Packaging } from 'kheops-graphql';
import { FormatLogisticalPackaging, FormatMeasurementUnit, FormatPackagingUnitPrice, FormatPackagingVolumePrice, formatPriceByBillingType } from 'kheops-utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';

interface ProductListPackagingInfoProps {
  packaging: Pick<Packaging, 'content_measurement_unit' | 'net_content' | 'price' | 'trade_item_unit_descriptor' | 'unit_quantity'>;
  baseUnit: Pick<Base_Unit, 'content_measurement_unit' | 'billing_type' | 'unit_net_content' | 'unit_quantity' | 'unit_type'>;
}

export default function ProductListPackagingInfo({ baseUnit, packaging }: ProductListPackagingInfoProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const currencyFormat = useCurrencyFormat();

  const volumePrice = useMemo(
    () => FormatPackagingVolumePrice(packaging, baseUnit),
    [],
  );

  const formattedVolumePrice = useMemo((): string => {
    return `${currencyFormat.format(volumePrice.value)}/${FormatMeasurementUnit(volumePrice.unit, 'fr', 1)}`;
  }, [volumePrice]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 1,
        backgroundColor: 'secondary.light',
        borderRadius: 4,
        py: 1,
        px: 2,
      }}
    >
      <Chip variant="tag" label={FormatLogisticalPackaging(packaging, 'fr', baseUnit)} />
      <Box
        sx={{
          textAlign: {
            xs: 'left',
            sm: 'right',
          },
        }}
      >
        <Typography color="primary" variant="labelLarge">
          {
            baseUnit.unit_type !== Base_Unit_Type_Enum.Bulk
              ? formatPriceByBillingType(currencyFormat.format(FormatPackagingUnitPrice(packaging)), baseUnit.billing_type)
              : formattedVolumePrice
          }
        </Typography>
        {baseUnit.unit_type !== Base_Unit_Type_Enum.Bulk && (
          <Typography variant="bodySmall" color="secondary" sx={{ whiteSpace: 'nowrap' }}>
            {t('products:the_base_unit')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
