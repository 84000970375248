import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import { currentContextAtom } from '../../state';
import ContractsUtils, { ContractPricingInfo } from '../utils/contracts.utils';

export interface PriceChipModeProps {
  contract: ContractPricingInfo;
}

export default function PriceModeChip({ contract }: PriceChipModeProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const { realm } = useAtomValue(currentContextAtom);

  const label = useMemo((): string => {
    return ContractsUtils.getPriceModeLabel(t, realm, contract.discount, contract.custom_price_list?.name);
  }, [contract]);

  return (
    <Chip
      variant="tag"
      label={label}
      icon={<DiscountIcon />}
    />
  );
}
