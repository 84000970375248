import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

export default function AddedPackagingBadge(): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Chip
      variant="tag"
      icon={<NewReleasesIcon />}
      label={t('base_unit_added')}
      sx={{
        backgroundColor: 'marketing1.main',
      }}
    />
  );
}
