import { Box, Chip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import useOpenable from '../../../hooks/useOpenable';
import { currentContextAtom } from '../../../state';
import FormDrawer from '../FormDrawer';
import Address from './Address';
import AddressForm, { AddressDraft } from './AddressForm';
import CountrySetFr from '../../../assets/world.json';
import { useUpdateCompanyMutation } from '../../../mutations/__generated__/updateCompany.generated';
import { UserRoleDocument } from '../../../queries/__generated__/userRole.generated';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { useUpsertAdditionalAddressMutation } from '../../../mutations/__generated__/upsertAdditionalAddress.generated';

export default function InvoicingAddressInfo(): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const { open, close, isOpen } = useOpenable();
  const [upsertInvoicingAddress] = useUpsertAdditionalAddressMutation();
  const [updateCompany] = useUpdateCompanyMutation({ refetchQueries: [UserRoleDocument], awaitRefetchQueries: true });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { companyId, companyLegalName, companyAddress, invoicingInstructions, invoicingPhoneNumber, invoicingEmail, invoicingAddress } = useAtomValue(currentContextAtom);

  const currentInvoicingAddress = useMemo(() => ({
    id: invoicingAddress?.id,
    name: invoicingAddress?.name || companyLegalName,
    address_1: invoicingAddress?.address_1 || companyAddress.formattedAddress,
    address_2: invoicingAddress?.address_2,
    country: invoicingAddress?.country || CountrySetFr.find(({ alpha2 }) => alpha2.toUpperCase() === companyAddress.countryCode)?.name || '',
    postal_code: invoicingAddress?.postal_code || companyAddress.postalCode!,
    city: invoicingAddress?.city || companyAddress.locality!,
  }), [invoicingAddress]);

  const onSubmit = async (values: AddressDraft): Promise<void> => {
    const { data } = await upsertInvoicingAddress({
      variables: {
        address: values.address,
      },
    });

    if (data?.insert_additional_address_one?.id) {
      await updateCompany({
        variables: {
          companyId,
          companyUpdateData: {
            invoicing_address_id: data.insert_additional_address_one.id,
            invoicing_instructions: values.additionalInfo,
            invoicing_email: values.email,
            invoicing_phone_number: values.phoneNumber,
          },
        },
      });

      setCommonSnackbarProps({
        label: t('settings:invoicing_address_updated'),
        snackbarProps: {
          open: true,
        },
      });
    }

    close();
  };

  return (
    <>
      <Box
        sx={{
          '& .MuiTypography-root': {
            lineHeight: 'normal',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Address
            address={currentInvoicingAddress}
            additionalInfoTitle={t('settings:additional_info')}
            additionalInfo={invoicingInstructions}
            email={invoicingEmail}
            phoneNumber={invoicingPhoneNumber}
          />
          <Chip
            variant="assistive"
            onClick={open}
            icon={<EditOutlinedIcon />}
            sx={{ alignSelf: 'start' }}
            label={t('common:modify')}
          />
        </Box>
      </Box>
      <FormDrawer
        title={t('settings:edit_invoicing_address')}
        open={isOpen}
        onClose={close}
        FormComponent={AddressForm}
        formProps={{
          address: currentInvoicingAddress,
          phoneNumber: invoicingPhoneNumber,
          email: invoicingEmail,
          additionalInfo: invoicingInstructions,
          onSubmit,
        }}
      />
    </>
  );
}
