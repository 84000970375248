import { Box, Button, Chip, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams, useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import {
  FormatBaseUnitQuantity,
  FormatLogisticalPackaging,
  FormatMeasurementUnit,
  FormatPackagingUnitPrice,
  FormatPackagingVolumePrice,
  formatPriceByBillingType,
  formatSuggestedRetailPrice,
  getConvertedSuggestedRetailPrice,
} from 'kheops-utils';
import { Availability_Status_Enum, Base_Unit_Type_Enum, Billing_Type_Enum, Business_Profile_Enum, Duration_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import i18n from '../i18n';
import { FormatPackagingLabels } from '../packaging/packaging-formatter';
import { ProductVersionByIdQuery, useProductVersionByIdQuery } from '../queries/__generated__/productVersionById.generated';
import { RoutePaths } from '../routes/AppRoutes';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { linkStyle } from '../common/utils/style.utils';
import BackBar from '../common/components/BackBar';
import FileUtils from '../common/utils/file.utils';
import { PageWrapper } from '../common/components/PageWrapper';
import SizedImage from '../common/components/SizedImage';
import { contractsAtom, currentContextAtom } from '../state';
import ProductNutritionFacts from './productPage/Product/ProductNutritionFacts';
import BillingTypeCard from './BillingTypeCard';
import ProductUpdateMenu from './ProductUpdateMenu';
import BaseUnitPhotosDisplay from './BaseUnitPhotosDisplay';
import BackButton from '../common/components/BackButton';
import ExpandableText from '../common/components/ExpandableText';
import ShelfLifeDurationDisplay from './productPage/Product/ShelfLifeDurationDisplay';
import ShortShelfLifeDurationChip from './productPage/Product/ShortShelfLifeDurationChip';
import { formatPackagingPriceIncludingDescriptor, getBaseUnitExcludedTaxMargin } from '../common/utils/common.utils';
import { ContractPreview } from '../hooks/useContracts';
import usePackagingCTAButton from '../hooks/usePackagingCTAButton';

// Extract type of packaging from the query ProductVersionByIdQuery
// Using `Array<>[number]` let's us get a type from a value in an array.
type BaseUnit = NonNullable<ProductVersionByIdQuery['product']>[number]['base_units'][number]
type ProductPackaging = BaseUnit['packagings'][number];

export default function PackagingPage(): React.JSX.Element {
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['common', 'contracts', 'products', 'price']);
  const { productSku, companyId, productId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const context = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(contractsAtom);
  const currencyFormat = useCurrencyFormat();
  const { data: productData, loading } = useProductVersionByIdQuery({
    variables: {
      companyId,
      productSku: Number(productSku),
      productId,
      shouldCheckHistory: !!productId,
    },
  });
  const [baseUnit, setBaseUnit] = useState<BaseUnit>();
  const [packaging, setPackaging] = useState<ProductPackaging>();
  const ctaButton = usePackagingCTAButton({ packagingId: packaging?.id, supplierCompanyId: companyId! });
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const isBuyer = useMemo(() => context.realm === Business_Profile_Enum.Buyer, [context]);
  const contract = useMemo((): ContractPreview | undefined => {
    if (!isBuyer) {
      return;
    }

    return contracts.find(({ supplyingCompanyId }) => supplyingCompanyId === companyId);
  }, [contracts, companyId, isBuyer]);

  const product = useMemo(() => {
    if (productData?.product) {
      return productData.product[0];
    }
    if (productData?.product_history) {
      return productData.product_history[0];
    }
  }, [productData]);

  const handleNavigation = useCallback((baseUnitParam: string, packagingParam: string, replace?: boolean): void => {
    setSearchParams(
      {
        ...searchParams,
        csu: baseUnitParam,
        packaging: packagingParam,
      },
      { replace },
    );
  }, [searchParams]);

  const productBaseunitOptions = useMemo(() => {
    if (!product || !baseUnit || !product.base_units.length) {
      return undefined;
    }

    const baseunitsComponents = (product.base_units as BaseUnit[]).reduce((acc, value) => {
      const label = FormatBaseUnitQuantity(value, 'fr', numberFormat) || '';
      const csuParam = value.sku?.toString() || '';
      const packagingParam = value.packagings[0].sku.toString() || '';
      const baseUnitIsSelected = value.id === baseUnit.id;

      if (value.availability === Availability_Status_Enum.Available || !isBuyer) {
        acc.push((
          <Box
            sx={{
              height: 116,
              width: 112,
              p: 1,
              cursor: 'pointer',
              borderRadius: 4,
              boxSizing: 'border-box',
              border: '1px solid',
              borderColor: baseUnitIsSelected ? 'text' : 'divider',
              backgroundColor: baseUnitIsSelected ? 'greys.primary' : 'none',
              '&:hover': {
                borderColor: 'text.primary',
              },
              textAlign: 'center',
            }}
            onClick={() => handleNavigation(csuParam, packagingParam)}
          >
            <SizedImage
              src={FileUtils.BuildPackagingImageUrl({
                photo: value.main_photo,
                type: product.sub_family,
                size: { width: 69, height: 90, fit: 'contain' },
              })}
              alt={t('products:csu')}
              width={64}
              height={64}
              sx={{
                borderRadius: 0,
                mx: 'auto',
              }}
            />
            <Typography variant="labelLarge">
              {label}
            </Typography>
          </Box>
        ));
      }

      return acc;
    }, [] as React.JSX.Element[]);

    return (
      <Box>
        <Typography variant="titleMedium" sx={{ mb: 1 }}>{t('products:base_unit', { count: product.base_units.length })} :</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {baseunitsComponents}
        </Box>
      </Box>
    );
  }, [product, baseUnit, handleNavigation, isBuyer]);

  const labelImages = useMemo(() => {
    if (!product?.labels.length) {
      return undefined;
    }

    const labels = FormatPackagingLabels(product.labels).map((labelImage) => {
      return (
        <Box
          key={labelImage.alt}
          component="img"
          alt={labelImage.alt}
          src={labelImage.src}
          sx={{
            height: 40,
            width: 40,
            objectFit: 'contain',
          }}
        />
      );
    });

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {labels}
      </Box>
    );
  }, [product]);

  const adjustedPackaging = useMemo((): ProductPackaging | undefined => {
    if (!packaging) {
      return undefined;
    }

    if (!contract) {
      return packaging;
    }

    const customPriceListDiscount = contract.custom_price_list?.custom_price_list_packagings
      .find(({ packaging_sku }) => packaging.sku === packaging_sku)
      ?.discount;
    const discount = customPriceListDiscount || contract.discount;

    if (discount) {
      return {
        ...packaging,
        price: packaging.price * (1 - discount),
      };
    }

    return packaging;
  }, [contract, packaging]);

  const specificationsMap = useMemo(() => {
    const map = new Map<string, { label?: string; value?: string, component?: React.JSX.Element }>();

    if (!product || !baseUnit || !adjustedPackaging) {
      return map;
    }

    const volumePrice = FormatPackagingVolumePrice(adjustedPackaging, baseUnit);

    if (product.labels.length >= 1) {
      map.set('labels', {
        label: t('contracts:quality'),
        value: FormatPackagingLabels(product.labels).map(({ alt }) => alt).join(', '),
      });
    }

    map.set('basePackaging', {
      label: t('products:base_unit_one'),
      value: FormatBaseUnitQuantity(baseUnit, 'fr', numberFormat) || '',
    });

    map.set('packaging', {
      label: t('contracts:packaging_type'),
      value: FormatLogisticalPackaging(adjustedPackaging, 'fr', baseUnit) || '',
    });

    map.set('price', {
      label: t('price:price_without_tax'),
      value: `${formatPriceByBillingType(currencyFormat.format(adjustedPackaging.price), baseUnit.billing_type)}`,
    });

    map.set('unitPrice', {
      label: t('price:price_without_tax_per_base_unit'),
      value: `${formatPriceByBillingType(currencyFormat.format(FormatPackagingUnitPrice(adjustedPackaging)), baseUnit.billing_type)}`,
    });

    map.set('volumePrice', {
      label: t('price:price_without_tax_per_volume'),
      value: `${currencyFormat.format(volumePrice!.value)}/${FormatMeasurementUnit(volumePrice!.unit, 'fr', 1)}` || '',
    });

    map.set('tvaRate', {
      label: t('contracts:vat_rate'),
      value: new Intl.NumberFormat(i18n.resolvedLanguage, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'percent',
      }).format(product.vat_rate!),
    });

    map.set('shelf_life_duration', {
      component: <ShelfLifeDurationDisplay
        duration={product.shelf_life_duration}
        durationType={product.shelf_life_duration_type as Duration_Type_Enum | undefined}
        titleVariant="bodySmall"
        titleAndValueGap={0.5}
      />,
    });

    if (baseUnit.gtin) {
      map.set('gtin', {
        label: t('common:barcode'),
        value: baseUnit.gtin,
      });
    }

    if (product.ingredients) {
      map.set('ingredients', {
        label: t('products:ingredient_list'),
        value: product.ingredients,
      });
    }

    if (product.allergens) {
      map.set('allergens', {
        label: t('products:allergen_list'),
        value: product.allergens,
      });
    }
    //

    return map;
  }, [product, baseUnit, adjustedPackaging]);

  const canEdit = useMemo(() => {
    return context.companyId === product?.company_id;
  }, [product]);

  const productComponent = useMemo(() => {
    if (product && packaging && baseUnit) {
      const supplierLink = generatePath(RoutePaths.CONTRACT_COMPANY, { companyId });

      const isSelectedCSUOfTypeBulk = baseUnit.unit_type === Base_Unit_Type_Enum.Bulk;
      const shouldDisplayShortSHelfLifeDurationChip = product.shelf_life_duration_type === Duration_Type_Enum.Days && product.shelf_life_duration! <= 31;
      let suggestedRetailPriceMargin: number;
      let formattedSuggestedRetailPrice: string | undefined;
      let marginChipLabel: string;

      if (baseUnit.suggested_retail_price) {
        const baseUnitInfo = {
          suggestedRetailPrice: baseUnit.suggested_retail_price,
          billingType: baseUnit.billing_type,
          netContent: (baseUnit.unit_net_content || packaging.net_content) as number,
          unitType: baseUnit.unit_type!,
          measurementUnit: (baseUnit.content_measurement_unit || packaging.content_measurement_unit) as Measurement_Unit_Enum,
        };

        formattedSuggestedRetailPrice = formatSuggestedRetailPrice(baseUnitInfo);
        suggestedRetailPriceMargin = getBaseUnitExcludedTaxMargin(
          baseUnit.billing_type === Billing_Type_Enum.WeightDependent && baseUnit.unit_type !== Base_Unit_Type_Enum.Bulk
            ? getConvertedSuggestedRetailPrice(baseUnit.suggested_retail_price, baseUnitInfo.netContent, baseUnitInfo.measurementUnit)
            : baseUnit.suggested_retail_price,
          FormatPackagingUnitPrice(adjustedPackaging!),
          product.vat_rate!,
        );
        marginChipLabel = isDownLg
          ? `+ ${suggestedRetailPriceMargin}%`
          : t('price:percentage_of_margin', { margin: suggestedRetailPriceMargin });
      }

      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            gap: { xs: 3, sm: 2 },
          }}
        >
          <Box
            sx={{
              margin: {
                xs: 'auto',
                lg: 'initial',
              },
            }}
          >
            <BackButton
              to={RoutePaths.ROOT}
              sx={{
                mb: 2,
                display: {
                  xs: 'none',
                  lg: 'inline-flex',
                },
              }}
            />
            <BaseUnitPhotosDisplay
              mainPhoto={baseUnit.main_photo}
              photos={baseUnit.photos.map((photoData) => photoData.photo)}
              productSubFamily={product.sub_family}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="titleLarge">
                {product.name}
              </Typography>
              {isBuyer && (
                <Box
                  component={Link}
                  to={supplierLink}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ...linkStyle,
                  }}
                >
                  <Box
                    component="img"
                    src={FileUtils.BuildCompanyImageUrl({ photo: product.company.photos[0]?.photo, type: 'SUPPLIER', size: { height: 24, width: 24 } })}
                    sx={{ borderRadius: '50%', mr: 1, height: 24, width: 24 }}
                  />
                  <Typography variant="bodyMedium">{product.company.tradeName}</Typography>
                </Box>
              )}
              {product.description && (
                <ExpandableText
                  text={product.description}
                  lineClampThreshold={3}
                />
              )}
              {shouldDisplayShortSHelfLifeDurationChip && (
                <Box sx={{ alignSelf: 'start' }}>
                  <ShortShelfLifeDurationChip duration={product.shelf_life_duration!} />
                </Box>
              )}
            </Box>
            {labelImages}
            {productBaseunitOptions}
            <Box>
              <Typography variant="titleMedium" sx={{ mb: 1 }}>
                {t('common:packaging', { count: baseUnit.packagings.length })} :
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {
                  baseUnit.packagings.map((baseUnitPackaging) => (
                    <Chip
                      disabled={baseUnit.packagings.length === 1}
                      variant="filter"
                      onClick={() => handleNavigation(baseUnit.id, baseUnitPackaging.id, true)}
                      label={FormatLogisticalPackaging(baseUnitPackaging, 'fr', baseUnit)}
                      className={baseUnitPackaging.id === packaging.id ? 'Kheops-selected' : ''}
                    />
                  ))
                }
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'divider',
                justifyContent: 'space-between',
                py: 3,
                px: {
                  xs: 1,
                  sm: 2,
                },
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant="headlineMedium" color="primary">
                    {
                      isSelectedCSUOfTypeBulk
                        ? specificationsMap.get('volumePrice')!.value
                        : specificationsMap.get('unitPrice')!.value
                    }
                  </Typography>
                  {formattedSuggestedRetailPrice && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography variant="bodyLarge">
                        {t('price:suggest_retail_price_acronym')} : {formattedSuggestedRetailPrice}
                      </Typography>
                      <Chip variant="tag" label={marginChipLabel!} />
                    </Box>
                  )}
                </Box>

                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="bodyLarge">
                    {formatPackagingPriceIncludingDescriptor(t, specificationsMap.get('price')!.value!, packaging.trade_item_unit_descriptor)}
                  </Typography>
                  {!isSelectedCSUOfTypeBulk && (
                    <Typography variant="bodyMedium" color="secondary">{specificationsMap.get('volumePrice')!.value}</Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  '& .KheopsButton-addToCart': {
                    width: '100%',
                  },
                }}
              >
                {ctaButton}
              </Box>
            </Box>
            <BillingTypeCard billingType={baseUnit.billing_type} />

            {canEdit && (
              <Box>
                <Button
                  component={Link}
                  to={generatePath(RoutePaths.PRODUCT, { productSku: `${product.sku}`, companyId: product.company_id })}
                  state={{ origin: RoutePaths.PACKAGING }}
                  variant="outlined"
                  startIcon={<EditIcon />}
                >
                  {t('common:modify')}
                </Button>
                <ProductUpdateMenu productId={product.id} baseUnitSku={baseUnit.sku!} origin={RoutePaths.PACKAGING} />
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }, [product, baseUnit, packaging, specificationsMap, companyId, isBuyer, isDownLg]);

  useEffect(() => {
    if (product) {
      const csuSearchParam = searchParams.get('csu') || '';
      const packagingSearchParam = searchParams.get('packaging') || '';

      const baseUnitResult = (product.base_units as BaseUnit[]).find(({ sku, id }) => sku === Number(csuSearchParam) || id === csuSearchParam);
      const packagingResult = (baseUnitResult?.packagings as ProductPackaging[]).find(({ sku, id }) => sku === Number(packagingSearchParam) || id === packagingSearchParam);

      setBaseUnit(baseUnitResult || (product.base_units[0] as BaseUnit));
      setPackaging(packagingResult || baseUnitResult?.packagings[0]);
    }
  }, [product, searchParams]);

  return (
    <>
      <BackBar to={RoutePaths.ROOT} />
      <PageWrapper>
        {!loading && (
          <Grid2
            container
            sx={{
              mb: 4,
              mt: 1,
              py: 4,
              px: { xs: 2, sm: 3, md: 4 },
              borderRadius: 6,
              backgroundColor: 'common.white',
            }}
          >
            <Grid2 size={{ xs: 12 }}>
              {productComponent}
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Typography
                variant="bodyMedium"
                color="secondary"
                sx={{
                  borderRadius: 2,
                  backgroundColor: 'secondary.light',
                  py: 1,
                  pl: 2,
                  mt: 3,
                  mb: 2,
                }}
              >
                {t('products:specifications')}
              </Typography>
            </Grid2>
            {Array.from(specificationsMap.values()).map((specification, index) => (
              <Grid2
                key={
                  // eslint-disable-next-line react/no-array-index-key
                  index
                }
                container
                sx={{
                  mb: 2,
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'divider',
                  paddingBottom: 1,
                  flexDirection: 'initial',
                }}
              >
                {specification.component
                  ? (specification.component)
                  : (
                    <>
                      <Grid2 size={{ xs: 12 }}>
                        <Typography
                          variant="bodySmall"
                          color="secondary"
                          sx={{ marginBottom: '4px' }}
                        >
                          {specification.label}
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <Typography variant="bodyMedium">{specification.value}</Typography>
                      </Grid2>
                    </>
                  )}
              </Grid2>
            ))}
            <Grid2 size={{ xs: 12, sm: 7 }}>
              <ProductNutritionFacts product={product!} />
            </Grid2>
          </Grid2>
        )}
      </PageWrapper>
    </>
  );
}
