import { Box, Chip, Grid2, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Billing_Type_Enum, Order_Status_Enum } from 'kheops-graphql';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import i18n from '../../i18n';
import AddedPackagingBadge from '../../orders/tracking-page/AddedPackagingBadge';
import { PackagingDisplay } from '../../orders/state/state';
import BillingTypeCard from '../../products/BillingTypeCard';
import FileUtils from '../utils/file.utils';
import PackagingWeightEditBox from './PackagingWeightEditBox';
import SizedImage from './SizedImage';
import VersionedValue from './VersionedValue';
import OrderItemEditBox from './OrderItemEditBox';
import useOrderItem from '../../hooks/useOrderItem';

interface PackagingDisplayBox {
  orderStatus: Order_Status_Enum;
  packagingDisplay: PackagingDisplay;
  index: number;
  orderId?: string;
  canEditQuantity?: boolean;
  canEditWeight?: boolean;
  shouldEditWeightBeEnabledByDefault?: boolean;
  shouldEditQuantityBeEnabledByDefault?: boolean;
  shouldDisplayAddedPackagingBadge?: boolean;
}
export default function PackagingDisplayBox({
  orderStatus,
  packagingDisplay,
  index,
  orderId,
  canEditQuantity,
  canEditWeight,
  shouldEditWeightBeEnabledByDefault,
  shouldEditQuantityBeEnabledByDefault,
  shouldDisplayAddedPackagingBadge,
}: PackagingDisplayBox): React.JSX.Element {
  const { t } = useTranslation(['common', 'price']);
  const currencyFormat = useCurrencyFormat();
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const { price, previousPrice, defaultPrice } = useOrderItem(packagingDisplay.id) || {};
  const isWeightDependent = useMemo(() => (
    packagingDisplay.base_unit?.billing_type === Billing_Type_Enum.WeightDependent
  ), [packagingDisplay]);

  return (
    <Grid2
      container
      sx={{
        '&:not(:first-child)': {
          borderTop: '1px solid',
          borderColor: 'divider',
        },
        pt: 4,
        pb: 1,
      }}
      columnSpacing={2}
    >
      <Grid2
        size={{ xs: 12, lg: 6 }}
        sx={{
          display: 'flex',
          pb: 2,
        }}
      >
        <SizedImage
          src={FileUtils.BuildPackagingImageUrl({
            photo: packagingDisplay.base_unit?.main_photo,
            type: packagingDisplay.product!.sub_family!,
            size: { height: 160, fit: 'contain' },
          })}
          borderRadius={0}
          alt={t('products:csu')}
          width={80}
          height={80}
        />
        <Box sx={{ ml: 1 }}>
          <Typography sx={{ overflowWrap: 'anywhere' }} variant="titleMedium">{packagingDisplay.product?.name}</Typography>
          <Box sx={{ my: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <Chip
              variant="tag"
              label={FormatBaseUnitQuantity(packagingDisplay.base_unit!, 'fr', numberFormat) || ''}
              sx={{ mr: 1 }}
            />
            <Typography sx={{ ml: 1 }} variant="bodySmall">
              {FormatLogisticalPackaging(packagingDisplay, 'fr', packagingDisplay.base_unit!) || ''}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                sm: 'row',
                xs: 'column',
              },
              mt: 1,
              gap: 1,
            }}
          >
            {isWeightDependent && (
              <BillingTypeCard
                billingType={Billing_Type_Enum.WeightDependent}
                inlineVersion
              />
            )}
            {(shouldDisplayAddedPackagingBadge) && (
              <AddedPackagingBadge />
            )}
          </Box>

        </Box>
      </Grid2>
      <Grid2
        container
        size={{ xs: 12, lg: 6 }}
        sx={{
          pb: 2,
        }}
        rowSpacing={2}
      >
        <Grid2 size={{ xs: 12, sm: 4, lg: 5 }}>
          <Typography variant="bodySmall" color="secondary">
            {`${t('common:quantity')}`}
          </Typography>
          <OrderItemEditBox
            packagingId={packagingDisplay.id}
            orderStatus={orderStatus}
            canEditQuantity={canEditQuantity}
            shouldEditBeEnabledByDefault={shouldEditQuantityBeEnabledByDefault}
            index={index}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 4 }}>
          {isWeightDependent
            ? (
              <>
                <Typography variant="bodySmall" color="secondary">
                  {t('common:total_weight')}
                </Typography>
                <PackagingWeightEditBox
                  packagingId={packagingDisplay.id}
                  canEditWeight={canEditWeight}
                  shouldEditBeEnabledByDefault={shouldEditWeightBeEnabledByDefault}
                  orderStatus={orderStatus}
                  orderId={orderId}
                />
              </>
            )
            : (
              <>
                <Typography variant="bodySmall" color="secondary">
                  {t('price:price_without_tax_per_packaging')}
                </Typography>
                <Typography>{currencyFormat.format(packagingDisplay.price)}</Typography>
              </>
            )}
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 4, lg: 3 }}>
          {price !== undefined && (
            <>
              <Typography variant="bodySmall" color="secondary">
                {t('price:total_price_without_tax')}
              </Typography>
              <VersionedValue
                currentValue={price}
                previousValue={defaultPrice !== price ? defaultPrice : previousPrice}
                color="text.main"
                valueFormatter={currencyFormat.format}
                shouldHighlightDifference
                variant="titleMedium"
              />
            </>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
