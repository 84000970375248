import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SuggestedOrderIcon from '../../assets/icons/suggested_order.svg?react';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';

interface OrderSuggestionChipProps {
  short?: boolean;
}

export default function OrderSuggestionChip({ short }: OrderSuggestionChipProps): React.JSX.Element {
  const { t } = useTranslation(['order']);

  return (
    <BlackBackgroundTooltip disableHoverListener={!short} title={t('order:supplier_suggestion')} arrow>
      <Chip
        variant="tag"
        label={short ? '' : t('order:supplier_suggestion')}
        icon={<SuggestedOrderIcon />}
      />
    </BlackBackgroundTooltip>
  );
}
