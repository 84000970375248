import { Box } from '@mui/material';
import { Brand_Enum } from 'kheops-graphql';
import FileUtils from '../utils/file.utils';

interface BrandBoxProps {
  brand: Brand_Enum;
  height?: number;
  width?: number;
  logoWidth?: number;
}

export default function BrandBox({ brand, height, width, logoWidth }: BrandBoxProps): React.JSX.Element {
  return (
    <Box
      className="Kheops-brand"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        width: width || 40,
        height: height || 40,
      }}
    >
      <Box
        className="Kheops-brandLogo"
        component="img"
        src={FileUtils.BuildBrandLogoURL(brand)}
        alt="logo"
        loading="lazy"
        sx={{
          width: logoWidth || 24,
        }}
      />
    </Box>
  );
}
