import { Box, Grid2, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useAtomValue } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import { companyPackagingsAtom } from '../state';
import PackagingsPricesFormRow from './PackagingsPricesFormRow';
import { insensitiveStringIncludes } from '../../common/utils/common.utils';
import useSearchProducts from '../../products/common/useSearchProducts';
import SearchInput from '../../common/components/SearchInput';
import ProductSubFamilyFilter from '../../products/common/ProductSubFamilyFilter';

export default function PackagingsPricesForm(): React.JSX.Element {
  const { t } = useTranslation(['common', 'products', 'price']);
  const breakpoint = useCurrentBreakpoint();
  const companyPackagings = useAtomValue(companyPackagingsAtom);
  const [filteredProducts, setFilteredProducts] = useState(companyPackagings);

  const productFamiliesOptions = useMemo((): Product_Sub_Family_Name_Enum[] => {
    const options = new Set<Product_Sub_Family_Name_Enum>();

    companyPackagings.forEach(({ product }) => options.add(product.sub_family as Product_Sub_Family_Name_Enum));

    return [...options.values()];
  }, [companyPackagings]);

  const virtualItemSize = useMemo((): number => {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return 293;
      default:
        return 130;
    }
  }, [breakpoint]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VirtualRow: any = useCallback(({ index, style }: any) => (
    <div style={style} className="packagingRow">
      <PackagingsPricesFormRow packaging={filteredProducts[index]} />
    </div>
  ), [filteredProducts]);

  const handleProductSearchChange = useCallback((productFamilies: Product_Sub_Family_Name_Enum[], query: string): void => {
    const newFilteredProducts = companyPackagings.filter((packaging) => {
      const matchFamilies = !productFamilies.length || productFamilies.includes(packaging.product.sub_family as Product_Sub_Family_Name_Enum);
      const matchSearch = !query || insensitiveStringIncludes(packaging.product.name, query) || packaging.base_unit.gtin === query;

      return matchFamilies && matchSearch;
    });

    setFilteredProducts(newFilteredProducts);
  }, [companyPackagings]);

  useEffect(() => {
    setFilteredProducts(companyPackagings);
  }, [companyPackagings]);

  const {
    query,
    setQuery,
    productFamilyMap,
    setProductFamilies,
    selectedProductFamilyMap,
  } = useSearchProducts({ onChange: handleProductSearchChange, productFamiliesOptions });

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <Typography
          variant="bodySmall"
          sx={{
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          {t('common:product_other')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column-reverse',
              lg: 'row',
            },
            alignItems: 'center',
            gap: 1,
          }}
        >
          <ProductSubFamilyFilter
            productFamilyMap={productFamilyMap}
            setProductFamilies={setProductFamilies}
            selectedProductFamilyMap={selectedProductFamilyMap}
            sx={{
              width: {
                xs: '100%',
                lg: 400,
              },
            }}
          />
          <SearchInput
            value={query}
            onChange={setQuery}
            sx={{
              width: {
                xs: '100%',
                lg: 400,
              },
              height: 48,
              mt: 0.5,
            }}
          />
        </Box>
        <Grid2
          container
          columnSpacing={1}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
        >
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('products:base_unit_one')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('price:packaging_price_without_tax')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('price:catalog_price_without_tax')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('price:suggest_retail_price_tax_included')}
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ height: '100%' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Grid2
              container
              sx={{
                '& .packagingRow:last-of-type .MuiDivider-root': {
                  display: 'none',
                },
              }}
            >
              <FixedSizeList
                height={height}
                width="100%"
                itemSize={virtualItemSize}
                itemCount={filteredProducts.length}
                overscanCount={5}
              >
                {VirtualRow}
              </FixedSizeList>
            </Grid2>
          )}
        </AutoSizer>
      </Box>
    </>
  );
}
