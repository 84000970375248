import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Chip, CircularProgress } from '@mui/material';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { format } from 'date-fns';
import { Brand_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import { CompanyInfo } from './CompanyInfoCard';
import { commonSnackbarPropsAtom } from '../../common/state/state';

export interface ExportProductsButtonProps {
  supplierCompany: CompanyInfo;
}

function getFileNameByBrand(supplierCompany: CompanyInfo, brand?: Brand_Enum): string {
  const downloadDate = format(new Date(), 'yyyy-MM-dd-HH:mm');

  switch (brand) {
    case Brand_Enum.UExpress:
    case Brand_Enum.HyperU:
    case Brand_Enum.SuperU:
      return `${supplierCompany.tradeName}_Import_Ulis.xlsx`;
    default:
      return `{{${supplierCompany.tradeName}}}(${supplierCompany.legalName})-${downloadDate}.xlsx`;
  }
}

export default function ExportProductsButton({ supplierCompany }: ExportProductsButtonProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'error', 'common']);
  const { companyId, companyBrand } = useAtomValue(currentContextAtom);
  const [exportLoading, setExportLoading] = useState(false);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const handleExport = async (): Promise<void> => {
    setExportLoading(true);

    try {
      const fileName = getFileNameByBrand(supplierCompany, companyBrand || undefined);
      const res = await axios.get(`/export/company/${supplierCompany.id}/products?&buyerCompanyId=${companyId}`, { responseType: 'blob' });

      fileDownload(res.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } catch {
      setCommonSnackbarProps({
        label: t('error:an_error_occured'),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'error',
        },
      });
    }

    setExportLoading(false);
  };

  return (
    <Chip
      variant="assistive"
      icon={exportLoading ? <CircularProgress /> : <FileDownloadOutlinedIcon />}
      label={t('products:export_products')}
      onClick={handleExport}
      disabled={exportLoading}
    />
  );
}
