import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import i18n from '../../i18n';
import BlackBackgroundTooltip from './BlackBackgroundTooltip';

interface DiscountChipProps {
  discount: number;
  toolTipEnabled?: boolean;
}
export default function DiscountChip({ discount, toolTipEnabled = false }: DiscountChipProps): React.JSX.Element {
  const percentFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
  const { t } = useTranslation('common');

  return (
    <BlackBackgroundTooltip title={t('contracts:discount')} disableHoverListener={!toolTipEnabled}>
      <Chip variant="tag" label={`-${percentFormatter.format(discount)}`} icon={<DiscountIcon />} />
    </BlackBackgroundTooltip>
  );
}
