import { Chip } from '@mui/material';
import { MouseEventHandler } from 'react';

interface ProductFamilyChipProps {
  label: string;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export default function ProductFamilyChip({ label, selected, onClick }: ProductFamilyChipProps): React.JSX.Element {
  return (
    <Chip
      label={label}
      variant="filter"
      className={selected ? 'Kheops-selected' : ''}
      onClick={onClick}
    />
  );
}
