import { Box, Button, Divider, Grid2, MenuItem, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BlockIcon from '@mui/icons-material/Block';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Comment_Insert_Input } from 'kheops-graphql';
import useOpenable from '../../../hooks/useOpenable';
import { userAtom } from '../../../state';
import { useInsertCommentMutation } from '../../../mutations/__generated__/insertComment.generated';
import { OrderComment } from '../../state/state';
import KheopsAvatar from '../../../common/components/KheopsAvatar';
import KheopsMenu from '../../../common/components/KheopsMenu';
import ConfirmDialog from '../../../common/components/ConfirmDialog';
import { dateAsRelative } from '../../../common/utils/date.utils';

interface CommentProps {
  comment: OrderComment;
}

export default function CommentCard({ comment }: CommentProps): React.JSX.Element {
  const { t } = useTranslation(['comments', 'common']);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { id: userId } = useAtomValue(userAtom);
  const { isOpen, open, close } = useOpenable();
  const [insertComment, { loading }] = useInsertCommentMutation({ refetchQueries: ['commentsByOrder'] });
  const [editMode, setEditMode] = useState(false);
  const [newComment, setNewComment] = useState(comment.content);
  const isCommentOwner = comment.user_id === userId;
  // Do not display relative time for more than 2 days old comment
  const modifiedDate = new Date(comment.modified_date);
  const dateDisplay = dateAsRelative(modifiedDate);
  const displayName = `${comment.user.first_name} ${comment.user.last_name}`;
  const ref = useRef<HTMLTextAreaElement>(null);

  const performInsertComment = (commentInsertInput: Comment_Insert_Input): void => {
    insertComment({
      variables: {
        comment: {
          ...commentInsertInput,
          reference_id: comment.reference_id,
          user_id: comment.user_id,
          order_reference_id: comment.order_reference_id,
        },
      },
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setMenuAnchorEl(null);
  };

  const handleEdit = (): void => {
    handleMenuClose();
    setEditMode(true);
  };

  const handleCancelClick = (): void => {
    setEditMode(false);
  };

  const handleUpdateClick = (): void => {
    performInsertComment({ content: newComment });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewComment(event.target.value);
  };

  const handleDeleteConfirm = (confirm: boolean): void => {
    if (confirm) {
      performInsertComment({ deleted: true });
    }

    handleMenuClose();
    close();
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.select();
    }
  }, [editMode]);

  const commentContent = useMemo((): React.JSX.Element => {
    if (comment.deleted) {
      return (
        <Typography variant="bodyMedium" color="secondary" sx={{ display: 'flex', alignItems: 'center' }}>
          <BlockIcon sx={{ fontSize: '14px', mr: 1 }} />
          {t('comments:this_comment_has_been_deleted')}
        </Typography>
      );
    }

    if (editMode) {
      return (
        <>
          <TextField
            sx={{
              width: '100%',
            }}
            multiline
            placeholder={t('comments:share_something_about_this_order')}
            value={newComment}
            onChange={handleCommentChange}
            inputRef={ref}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 3, mt: 1 }}>
            <Button onClick={handleCancelClick} variant="text">
              {t('common:cancel')}
            </Button>
            <LoadingButton
              onClick={handleUpdateClick}
              variant="contained"
              disabled={!newComment}
              loading={loading}
            >
              {t('common:update')}
            </LoadingButton>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box
          sx={{
            whiteSpace: 'pre-wrap',
            display: 'inline',
          }}
        >
          {comment.content!}
        </Box>
        {comment.modified_date !== comment.created_date && (
          <Typography variant="caption">
            &nbsp;({t('common:modified')})
          </Typography>
        )}
      </>
    );
  }, [comment, editMode, newComment]);

  return (
    <>
      <Grid2 container rowSpacing={1}>
        <Grid2 size={{ xs: 12 }} sx={{ display: 'flex' }}>
          <KheopsAvatar size={40} userAvatars={comment.user} />
          <Box sx={{ ml: 1, flex: 1 }}>
            <Typography variant="bodyMedium">{displayName}</Typography>
            <Tooltip
              arrow
              placement="right-end"
              title={modifiedDate.toLocaleString()}
            >
              <Typography variant="bodySmall" color="secondary">
                {dateDisplay}
              </Typography>
            </Tooltip>
          </Box>
          { isCommentOwner && !comment.deleted && (
            <>
              <Button variant="text" onClick={handleMenuClick}>
                <MoreVertIcon />
              </Button>
              <KheopsMenu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!menuAnchorEl}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEdit} disableRipple>
                  <EditOutlinedIcon />
                  {t('common:modify')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={open} disableRipple>
                  <DeleteOutlineIcon />
                  {t('common:delete')}
                </MenuItem>
              </KheopsMenu>
            </>
          )}
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Typography variant="bodyMedium">{ commentContent }</Typography>
        </Grid2>
      </Grid2>
      <ConfirmDialog
        open={isOpen}
        onClose={handleDeleteConfirm}
        message={t('comments:delete_your_comment_description')}
        title={t('comments:delete_your_comment')}
        confirmLabel={t('comments:delete_comment')}
        cancelLabel={t('common:cancel')}
      />
    </>
  );
}
