import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BusinessProfile } from 'kheops-graphql';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { SUPPORTED_IMAGE_TYPES, useUploadFile } from '../../hooks/useUploadFile';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { CompanyProfileForm } from './form.type';

interface CompanyPhotoFormProps {
  companyId: string;
}
export default function CompanyPhotoForm({ companyId }: CompanyPhotoFormProps): React.JSX.Element {
  const { t } = useTranslation('common');
  const { setValue, watch } = useFormContext<CompanyProfileForm>();
  const displayedPhoto = watch('photo');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    handleFileChange,
    fileParameters,
    isFileUploadLoading,
  } = useUploadFile({ type: 'COMPANY_PHOTOS', pathId: companyId });

  const handleButtonClick = (): void => {
    fileInputRef.current!.click();
  };

  const fileChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event);

    // empty the value attribute of the input file so we can re-upload the same file in case of error
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }, []);

  useEffect(() => {
    if (fileParameters) {
      setValue('photo', fileParameters, { shouldDirty: true });
    }
  }, [fileParameters]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
      }}
    >
      <SizedImage
        src={
          FileUtils.BuildCompanyImageUrl({
            photo: displayedPhoto,
            type: BusinessProfile.Supplier,
            size: {
              height: 124,
              width: 124,
              fit: 'cover',
            },
          })
        }
        sx={{
          borderRadius: 10,
        }}
        height={124}
        width={124}
        alt="company_photo"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography variant="bodySmall" color="secondary">{t('common:profile_image')}</Typography>
        <Chip
          disabled={isFileUploadLoading}
          variant="assistive"
          icon={isFileUploadLoading ? <CircularProgress /> : <AddAPhotoOutlinedIcon />}
          onClick={handleButtonClick}
          label={t('common:change_photo')}
        />
      </Box>
      <Box
        ref={fileInputRef}
        component="input"
        type="file"
        accept={SUPPORTED_IMAGE_TYPES.join(', ')}
        sx={{ display: 'none' }}
        onChange={fileChanged}
      />
    </Box>
  );
}
