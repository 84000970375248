import { Divider, Grid2, GridSize } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { AlgoliaHit } from 'instantsearch.js';
import { Contract, Custom_Price_List_Packaging, Maybe } from 'kheops-graphql';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import SearchUtils from '../../common/utils/search.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { RoutePaths } from '../../routes/AppRoutes';
import { ProductHit } from '../search';
import { DisplayMode } from '../state/state';
import SearchProductCard from './SearchProductCard';
import SearchProductRow from './SearchProductRow';

export type SearchContract = Pick<Contract, 'status' | 'discount' | 'supplyingCompanyId' | 'buyingCompanyId'> &
  {
    custom_price_list?: Maybe<{
      custom_price_list_packagings: Pick<Custom_Price_List_Packaging, 'company_id' | 'discount' | 'packaging_sku'>[]
    }>
  };

export interface SearchProductHitOptions {
  hideCompany?: boolean;
}
type SearchProductHitProps = SearchProductHitOptions
  & {
    hit: AlgoliaHit<ProductHit>;
    displayMode: DisplayMode;
    sizes?: { [key: string]: GridSize };
    contract?: SearchContract;
  }

export interface ProductHitInfo {
  price: string;
  productUrl: string;
  companyUrl: string;
}

export default function SearchProductHit({ hit, hideCompany, displayMode, sizes, contract }: SearchProductHitProps): React.JSX.Element {
  const currencyFormat = useCurrencyFormat();
  const adjustedHit = useMemo(() => {
    if (contract) {
      const customPriceListDiscount = contract.custom_price_list?.custom_price_list_packagings
        .find(({ packaging_sku }) => parseInt(hit.objectID.split('-')[0], 10) === packaging_sku)
        ?.discount;
      const discount = 1 - (customPriceListDiscount || contract.discount);

      return {
        ...hit,
        price: hit.price * discount,
        volumePrice: {
          ...hit.volumePrice,
          value: hit.volumePrice.value * discount,
        },
      };
    }

    return hit;
  }, [hit, contract]);

  const productHitInfo = useMemo((): ProductHitInfo => {
    const price = SearchUtils.getFormatedUnitPrice(adjustedHit, currencyFormat.format);

    const packagingSku = adjustedHit.objectID.split('-')[0];

    return {
      price,
      productUrl: `${generatePath(RoutePaths.PACKAGING, { productSku: adjustedHit.productSku, companyId: adjustedHit.company_id })}?csu=${adjustedHit.base_unit_sku}&packaging=${packagingSku}`,
      companyUrl: generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: adjustedHit.company_id }),
    };
  }, [adjustedHit]);

  const cardSizes = useMemo((): ResponsiveStyleValue<GridSize> => {
    return { xs: 12, sm: 6, md: 6, lg: 4, xl: 3, ...sizes };
  }, [sizes]);

  return (
    displayMode === DisplayMode.GRID
      ? (
        <Grid2 key={hit.objectID} size={cardSizes}>
          <SearchProductCard hit={adjustedHit} productHitInfo={productHitInfo} contractStatus={contract?.status} hideCompany={hideCompany} />
        </Grid2>
      )
      : (
        <>
          <SearchProductRow hit={adjustedHit} productHitInfo={productHitInfo} contractStatus={contract?.status} />
          <Grid2
            size={{ xs: 12 }}
            sx={{
              '&:(last-child)': {
                display: 'none',
              },
            }}
          >
            <Divider />
          </Grid2>
        </>
      )
  );
}
