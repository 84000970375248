import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';

type SearchInputPropsWithoutOnChange = Omit<OutlinedInputProps, 'onChange'>
interface SearchInputProps extends SearchInputPropsWithoutOnChange {
  onChange: (s: string) => void;
}

export default function SearchInput({ value, onChange, ...inputProps }: SearchInputProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return (
    <OutlinedInput
      value={value}
      placeholder={t('common:search')}
      onChange={handleChange}
      endAdornment={!!value && (
        <CloseIcon
          onClick={() => onChange('')}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />
      )}
      startAdornment={(
        <SearchIcon
          sx={{
            marginRight: 1,
            padding: 0.5,
            borderRadius: 4,
            color: 'white',
            backgroundColor: 'text.primary',
          }}
        />
      )}
      {...inputProps}
      sx={{
        pl: 1,
        '& .MuiOutlinedInput-input': {
          pr: {
            xs: 1,
            sm: 2,
          },
        },
        borderRadius: 100,
        ...inputProps?.sx,
      }}
    />
  );
}
