import { Box, Typography } from '@mui/material';
import React from 'react';

interface PropertyCardProps {
  title: string;
  subTitles: React.JSX.Element[];
  actionButton?: React.JSX.Element;
  onClick?: () => void;
}

export default function PropertyCard({ onClick, title, subTitles, actionButton }: PropertyCardProps): React.JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: 'surfaceContainerLow.main',
        p: 2,
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': onClick
          ? {
            backgroundColor: 'surfaceContainer.main',
            cursor: 'pointer',
          }
          : undefined,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Typography sx={{ textAlign: 'start' }} variant="bodyLarge">{title}</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
          }}
        >
          {subTitles}
        </Box>
      </Box>
      {actionButton}
    </Box>
  );
}
