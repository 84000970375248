import { Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { useAddOrderToBasket } from '../../hooks/useAddOrderToBasket';

export interface AddOrderToBasketButtonProps {
  orderReferenceId: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

export default function AddOrderToBasketButton({ orderReferenceId, fullWidth, onClick }: AddOrderToBasketButtonProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const { addOrderToBasket: handleAddToBasketClick, addedToBasket } = useAddOrderToBasket({
    orderReferenceId,
    onClick,
  });

  return (
    <Button
      variant="tonal"
      startIcon={addedToBasket ? <CheckCircleOutlineOutlinedIcon /> : <AddShoppingCartIcon />}
      disabled={addedToBasket}
      onClick={handleAddToBasketClick}
      fullWidth={fullWidth}
    >
      {t(addedToBasket ? 'common:added' : 'basket:add_to_basket')}
    </Button>
  );
}
