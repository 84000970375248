import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Card, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Brand_Enum } from 'kheops-graphql';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import { useUpdateContractsMutation } from '../../mutations/__generated__/updateContracts.generated';
import { CustomPriceListsByCompanyIdDocument } from '../../queries/__generated__/customPriceListsByCompanyId.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { signedContractsAtom } from '../../state';
import useOpenable from '../../hooks/useOpenable';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import SearchInput from '../../common/components/SearchInput';
import GlobalDiscountRow from './GlobalDiscountRow';
import GlobalDiscountFormDialog, { GlobalDiscountFormDialogResponse } from './GlobalDiscountFormDialog';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import { RoutePaths } from '../../routes/AppRoutes';

export interface GlobalDiscountInfo {
  contractId: string;
  tradeName: string;
  brand: Brand_Enum;
}

interface GlobalDiscountsMap {
  [discount: number]: GlobalDiscountInfo[];
}

export default function GlobalDiscountList(): React.JSX.Element {
  const { t } = useTranslation(['price']);
  const signedContracts = useAtomValue(signedContractsAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { isOpen, open, close } = useOpenable();
  const [query, setQuery] = useState('');
  const [updateContracts, { data }] = useUpdateContractsMutation({ refetchQueries: [CustomPriceListsByCompanyIdDocument, ContractsAsSupplierDocument] });

  const globalDiscountsMap = useMemo((): GlobalDiscountsMap => {
    return signedContracts.reduce((acc, { id, discount, company: { tradeName, brand } }) => {
      const globalDiscountInfo = { contractId: id, tradeName, brand: brand! };

      if (discount) {
        if (acc[discount]) {
          acc[discount].push(globalDiscountInfo);
        } else {
          acc[discount] = [globalDiscountInfo];
        }
      }

      return acc;
    }, {} as GlobalDiscountsMap);
  }, [signedContracts]);

  const globalDiscountRows = useMemo((): React.JSX.Element[] => {
    return Object.keys(globalDiscountsMap)
      .sort((a, b) => parseFloat(b) - parseFloat(a))
      .reduce((acc, discountKey) => {
        const discount = parseFloat(discountKey);
        const globalDiscountInfo = globalDiscountsMap[discount];

        if (!query || globalDiscountInfo.some(({ tradeName }) => tradeName.toLocaleLowerCase().includes(query.toLocaleLowerCase()))) {
          acc.push(<GlobalDiscountRow key={discount} discount={discount} globalDiscountInfo={globalDiscountsMap[discount]} />);
        }

        return acc;
      }, [] as React.JSX.Element[]);
  }, [globalDiscountsMap, query]);

  const handleGlobalDiscountFormDialogClose = (response?: GlobalDiscountFormDialogResponse): void => {
    close();

    if (!response) {
      return;
    }

    updateContracts({
      variables: {
        contractIds: response.contractIds,
        contractInput: {
          discount: response.discount / 100,
          custom_price_list_id: null,
        },
      },
    });
  };

  useEffect((): void => {
    if (data?.update_contract_many?.length) {
      setCommonSnackbarProps({
        label: t('price:new_global_discount_added'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [data]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        overflow: 'auto',
        height: {
          lg: 'calc(100% - 48px)',
        },
        p: 2,
      }}
    >
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          flexDirection: 'column',
          alignItems: 'start',
          p: 1.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            my: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <BackButton
              to={RoutePaths.NAVIGATION_MAIN}
              shouldCheckHistory={false}
              Icon={ArrowBackIcon}
              compact
            />
            <DiscountIcon height={28} width={28} />
            <Typography variant="titleLarge">
              {t('price:prices')}
            </Typography>
          </Box>
        </Box>
      </TopBar>
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DiscountIcon height={28} width={28} />
          <Typography variant="titleLarge">
            {t('price:prices')}
          </Typography>
        </Box>
        {!!Object.keys(globalDiscountsMap).length && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ alignSelf: 'start' }}
            onClick={open}
          >
            {t('price:add_a_global_discount')}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          mt: {
            xs: 7,
            lg: 3,
          },
        }}
      >
        <Typography sx={{ mb: 0.5 }} variant="titleMedium">
          {t('price:global_discounts')}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          {t('price:global_discounts_description')}
        </Typography>
      </Box>
      {
        !Object.keys(globalDiscountsMap).length
          ? (
            <EmptyPagePlaceholder
              title={t('price:no_global_discount')}
              description={t('price:no_global_discount_description')}
              imageSrc={TrumpeterButterflyImage}
              onClick={() => open()}
              buttonLabel={t('price:add_a_global_discount')}
              buttonProps={{
                startIcon: <AddIcon />,
              }}
            />
          )
          : (
            <>
              <SearchInput
                sx={{
                  height: 48,
                  width: {
                    xs: '100%',
                    sm: 420,
                  },
                  my: 3,
                }}
                placeholder={t('price:search_a_store')}
                onChange={(inputQuery) => setQuery(inputQuery)}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {globalDiscountRows}
              </Box>
            </>
          )
        }
      {isOpen && <GlobalDiscountFormDialog open onClose={handleGlobalDiscountFormDialogClose} />}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          bottom: 0,
          position: 'fixed',
          width: 'calc(100vw - 32px)',
          maxWidth: 320,
          mx: 'auto',
          left: 0,
          right: 0,
          mb: 2,
        }}
        onClick={open}
      >
        {t('price:add_a_global_discount')}
      </Button>
    </Card>
  );
}
