import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from '@mui/material';
import PackagingPricesDetails from '../../company/prices/PackagingPricesDetails';
import useOpenable from '../../hooks/useOpenable';
import { ContractConditions } from '../state';

export interface OpenPackagingPricesButtonProps {
  offerInfo: ContractConditions;
}

export default function OpenPackagingPricesButton({ offerInfo }: OpenPackagingPricesButtonProps): React.JSX.Element {
  const { open, isOpen, close } = useOpenable();

  if (!offerInfo.custom_price_list_id && !offerInfo.discount) {
    return <></>;
  }

  return (
    <>
      <Button variant="text" onClick={open} sx={{ ml: 1 }}>
        <InfoOutlinedIcon />
      </Button>
      <PackagingPricesDetails
        companyId={offerInfo.supplyingCompanyId}
        customPriceListId={offerInfo.custom_price_list_id}
        subCatalogId={offerInfo.sub_catalog_id}
        globalDiscount={offerInfo.discount}
        open={isOpen}
        onClose={close}
      />
    </>
  );
}
